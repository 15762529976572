import Cookies from "js-cookie";
import { setAuthToken } from "../../config/axiosConfig";
import { CookieTypes } from "../../constant/enum";
import { commonReducerInterface } from "../../interfaces/actionInterfaces";
import { AUTH_ACTIONS, DELETE_ACCOUNT_ACTION } from "../actions/actionTypes";

const initialState = {
  authLoader: false,
  showOtpScreen: false,
  showOtpSignupScreen: false,
  token: null,
  refreshToken: null,
  expireTime: null,
  userData: {},
  isAuthenticated: false,
  closeDrawer: false,
  signInLoader: false,
  deleteAccountOptions: [],
  deleteAccountOptionsLoader: false,
  underMaintenance: false,
  xKey: null,
};

const authReducer = (state = initialState, action: commonReducerInterface) => {
  const prevState = { ...state };
  const { type, payload } = action;
  console.log("authReducer>", payload);
  switch (type) {
    case AUTH_ACTIONS.USER_LOGIN_REQUESTED:
      return {
        ...state,
        authLoader: true,
        showOtpScreen: false,
      };
    case AUTH_ACTIONS.USER_LOGIN_SUCCEEDED:
      return {
        ...state,
        authLoader: false,
        showOtpScreen: true,
      };
    case AUTH_ACTIONS.USER_LOGIN_FAILED:
      return {
        ...state,
        authLoader: false,
        showOtpScreen: false,
      };
    case AUTH_ACTIONS.LOGOUT_SUCCESS:
      Cookies.set(CookieTypes.TOKEN, null!);
      setAuthToken("");
      return {
        ...initialState,
        deleteAccountOptions: state.deleteAccountOptions,
      };
    case AUTH_ACTIONS.SET_USER_TOKEN:
      return {
        ...state,
        isAuthenticated: payload?.token ? true : false,
        token: payload?.token,
        refreshToken: payload?.refreshToken,
        expireTime: payload?.expireTime,
        xKey: payload?.xKey,
      };

    case AUTH_ACTIONS.SET_USER_DATA_REQUESTED:
      return {
        ...state,
        signInLoader: true,
      };
    case AUTH_ACTIONS.SET_USER_DATA:
      return {
        ...state,
        userData: payload,
        isAuthenticated: true,
      };
    case AUTH_ACTIONS.SET_USER_DATA_COMPLETED:
      return {
        ...state,
        signInLoader: false,
      };

    case AUTH_ACTIONS.OTP_VERIFICATION_REQUESTED:
      return {
        ...state,
        authLoader: true,
      };
    case AUTH_ACTIONS.OTP_VERIFICATION_FAILED:
      return {
        ...state,
        authLoader: false,
        // verificationStatus: true,
        // showOtpScreen: false,
      };
    case AUTH_ACTIONS.OTP_VERIFICATION_SUCCEEDED:
    case AUTH_ACTIONS.USER_SIGNUP_SUCCEEDED:
      setAuthToken(payload?.token);
      return {
        ...state,
        token: payload?.tokenData?.Token,
        refreshToken: payload?.tokenData?.RefreshToken,
        expireTime: payload?.tokenData?.ExpireTime,
        xKey: payload?.xKey,
        userData: payload?.userData,
        isAuthenticated: true,
        showOtpSignupScreen: false,
        access_uuid_id: payload?.tokenData?.access_uuid_id,
        authLoader: false,
        closeDrawer: true,
        showOtpScreen: false,
      };
    case AUTH_ACTIONS.SENT_OTP_FOR_SIGNUP_REQUESTED:
      return {
        ...state,
        authLoader: true,
        showOtpSignupScreen: false,
      };
    case AUTH_ACTIONS.SENT_OTP_FOR_SIGNUP_SUCCEEDED:
      return {
        ...state,
        authLoader: false,
        showOtpSignupScreen: true,
      };
    case AUTH_ACTIONS.SENT_OTP_FOR_SIGNUP_FAILED:
      return {
        ...state,
        authLoader: false,
        showOtpSignupScreen: false,
      };
    case AUTH_ACTIONS.RESEND_OTP_REQUESTED:
      return { ...state, authLoader: true };
    case AUTH_ACTIONS.RESEND_OTP_SUCCEEDED:
      return { ...state, authLoader: false };
    case AUTH_ACTIONS.RESEND_OTP_FAILED:
      return { ...state, authLoader: false };

    case AUTH_ACTIONS.CLOSE_OTP_SCREEN:
      return {
        ...state,
        showOtpScreen: false,
        showOtpSignupScreen: false,
      };

    case DELETE_ACCOUNT_ACTION.GET_DEL_ACC_OPTIONS_REQUESTED:
      return {
        ...state,
        deleteAccountOptionsLoader: true,
      };
    case DELETE_ACCOUNT_ACTION.GET_DEL_ACC_OPTIONS_SUCCESS:
      return {
        ...state,
        deleteAccountOptions: payload,
        deleteAccountOptionsLoader: false,
      };

    case DELETE_ACCOUNT_ACTION.GET_DEL_ACC_OPTIONS_FAILED:
      return {
        ...state,
        deleteAccountOptionsLoader: false,
      };

    case DELETE_ACCOUNT_ACTION.SEND_OTP_FOR_DEL_ACC_REQUESTED:
      return {
        ...state,
      };
    case DELETE_ACCOUNT_ACTION.SEND_OTP_FOR_DEL_ACC_SUCCEEDED:
      return {
        ...state,
      };
    case DELETE_ACCOUNT_ACTION.SEND_OTP_FOR_DEL_ACC_FAILED:
      return {
        ...state,
      };

    case DELETE_ACCOUNT_ACTION.CHANGE_ACC_STATUS_REQUESTED:
      return {
        ...state,
      };
    case DELETE_ACCOUNT_ACTION.CHANGE_ACC_STATUS_SUCCEEDED:
      return {
        ...state,
      };
    case DELETE_ACCOUNT_ACTION.CHANGE_ACC_STATUS_FAILED:
      return {
        ...state,
      };
    case AUTH_ACTIONS.UNDER_MAINTENANCE:
      return {
        ...state,
        underMaintenance: payload,
      };
    default:
      return { ...prevState };
  }
};

export default authReducer;
