import moment from "moment";
import en from "../locales/en.json";
import { DECRYPTION_KEY } from "./regex";
import forge from "node-forge";
import CONSTANTS from "../locales/en.json";
import store from "../redux/store";
import { defaultHeaders, setAuthToken } from "../config/axiosConfig";
import { BASE_URL } from "../config/apiUrls";
import Cookies from "js-cookie";
import { IConfig } from "../interfaces/authInterface";
import axios from "axios";
import { CookieTypes } from "../constant/enum";
import { actions } from "../redux";

var CryptoJS = require("crypto-js");

export const throttle = (fun: any, threshold: number = 2000) => {
  let execute = true,
    timeout: any = null;
  return (...args: any) => {
    if (execute) {
      fun(...args);
      execute = false;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        execute = true;
        timeout = null;
      }, threshold);
    }
  };
};

export const deepCopy = (data: any) => {
  if (data) {
    return JSON.parse(JSON.stringify(data));
  } else return data;
};

export const debounce = (func: any, threshold: number = 500) => {
  let debounceHandler: any = null;
  return (...args: any) => {
    if (debounceHandler) clearTimeout(debounceHandler);
    debounceHandler = setTimeout(() => func(...args), threshold);
  };
};

const isArrayNotEmpty = (array: Array<any>) =>
  array !== null && Array.isArray(array) && array.length > 0;

export const handleMessage = (response: any) => {
  if (typeof response === "string") {
    return response;
  } else if (isArrayNotEmpty(response?.Message)) {
    return response?.Message[0];
  } else if (response?.Message) {
    return response?.Message;
  } else if (isArrayNotEmpty(response?.data?.Message)) {
    return response?.data?.Message[0];
  } else if (response?.data?.Message) {
    return response?.data?.Message;
  } else if (response?.data?.result?.Message) {
    return response?.data?.result?.Message;
  } else if (isArrayNotEmpty(response?.response?.data?.Message)) {
    return response?.response?.data?.Message[0];
  } else if (response?.response?.data?.Message) {
    return response?.response?.data?.Message;
  } else if (response?.response?.data?.message) {
    return response?.response?.data?.message;
  } else if (response?.data?.message) {
    return response?.data?.message;
  } else {
    return en.errorMessages.SomethingWentWrong;
  }
};

export const mobileValidation = (mobile: string) => {
  let regex = /^[6-9]\d{9}$/;
  return regex.test(mobile);
};

export const hindiTextValidation = (text: string ) => {
  if(!text) return true;
  let regex = /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/;
  //let regex =/^[\u0900-\u097F\s]*$/
  return regex.test(text);
}
export const emailValidation = (email: string) => {
  let regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const restrictName = (text: string) =>
  text.replace(/[^A-Za-z]/g, "").trim();

export const restrictNameWithSpaces = (text: string) => {
  if (!text.trim()?.length) return "";
  let regex = /^[A-Za-z ]+$/;
  return regex.test(text) ? text : text.slice(0, -1);
};

export const restrictNumber = (text: string) =>
  text.replace(/[^0-9]/g, "").trim();

export const validate = (fields: any = {}, errorFields: object = {}) => {
  const validateFields = Object.keys(fields);
  let obj: any = errorFields;
  let error = false;
  validateFields.forEach((each) => {
    if (obj[each] === undefined) return;

    if (
      typeof fields[each] === "string" ||
      typeof fields[each] === "number" ||
      typeof fields[each] === "object"
    ) {
      if (!fields[each]) {
        error = true;
        obj[each] = en.errorMessages.requiredField;
      } else {
        switch (true) {
          case each?.toLocaleLowerCase()?.includes("email"):
            if (!validateEmail(fields[each]).status) {
              error = true;
              obj[each] = en.errorMessages.InvalidEmail;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("pincode"):
            if (fields[each]?.length < 6) {
              error = true;
              obj[each] = en.errorMessages.InvalidPincode;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("mobile") ||
            each?.toLocaleLowerCase()?.includes("phone"):
            if (fields[each]?.length < 10) {
              error = true;
              obj[each] = en.errorMessages.mobileError;
            }
            break;
          default:
            break;
        }
      }
    }

    // else if( fields["inputFields"].category_name==""){
    //   error = true;
    //     obj[each] = en.errorMessages.requiredField;

    // }
    else {
      // @ts-ignore: Unreachable code error
      if (!fields[each]?.id) {
        error = true;
        // @ts-ignore: Unreachable code error
        obj[each] = en.errorMessages.requiredField;
      }
    }
  });
  return { errorFields: { ...errorFields, ...obj }, error };
};

export const validateEmail = (email: string) => {
  const emailRegex =
    /^[A-Z0-9_-]+([\.][A-Z0-9_]+)*@[A-Z0-9-]+(\.[a-zA-Z]{2,3})+$/i;
  email = email?.trim();
  if (email == "" || email == undefined || email == null)
    return { status: false, error: en.errorMessages.requiredField };
  else if (!emailRegex.test(email))
    return { status: false, error: en.errorMessages.InvalidEmail };
  else return { status: true, error: "" };
};
export const getLocalStorageData = (key: string) => {
  const data = localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  } else {
    return null;
  }
};
export const setLocalStorageData = (key: string, value: any) => {
  if (value !== undefined) {
    const data = JSON.stringify(value);
    localStorage.setItem(key, data);
  }
};

// removing existing key from localStorage
export const removeLocalStorageData = (key: string) => {
  localStorage.removeItem(key);
};

export const decryptData = (encrypted: string) => {
  let decrypted = CryptoJS.AES.decrypt(encrypted, DECRYPTION_KEY);
  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
};

export const getParamsString = (obj: any) => {
  let paramString = "?";
  Object.keys(obj).forEach((_keys, index) => {
    if (obj[_keys]) {
      paramString +=
        _keys +
        "=" +
        obj[_keys] +
        (index + 1 !== Object.keys(obj).length ? "&" : "");
    }
  });
  return paramString;
};

export const getFilePath = (documents: any, document_id: number) => {
  try {
    let doc = documents.filter(
      (_doc: any) => _doc.document_type_id === document_id
    );
    return doc[0]["documents"]["document_path"];
  } catch (ex) {
    return "";
  }
};

export const findEndDate = (startDate, days) =>
  startDate
    ? moment(moment(startDate), "DD/MM/YYYY")
        .add(days, "days")
        .format("YYYY-MM-DD")
    : "";

export const compressSizer: any = (size) => {
  const MB = size / Math.pow(1024, 2);
  if (Math.round(MB) === 0) return 100;
  if (Math.round(MB) === 1) return 100;
  if (Math.round(MB) === 2) return 90;
  if (Math.round(MB) === 3) return 80;
  if (Math.round(MB) === 4) return 70;
  if (Math.round(MB) <= 5) return 60;
  if (Math.round(MB) <= 7) return 10;
  if (Math.round(MB) <= 10) return 10;
  if (Math.round(MB) <= 15) return 10;
  if (Math.round(MB) <= 20) return 10;
  if (Math.round(MB) > 20) return 10;
};

export const imgFileReader = (file, callback) => {
  const reader: any = new FileReader();
  reader.readAsDataURL(file);
  reader.addEventListener("load", () => {
    callback(reader.result);
  });
};

export const getEncryptedText = (text: string) => {
  return window.btoa(text.toString());
};
export const getDecryptedText = (text: any) => {
  return window.atob(text.toString());
};
export function capitalizeFirstLetter(str) {
  if (!str) return "";
  str = str?.split(" ");

  for (let i = 0, x = str?.length; i < x; i++) {
    str[i] = str[i][0]?.toUpperCase() + str[i]?.substr(1);
  }

  return str?.join(" ");
}

export const closeAllModals = () => {
  const collection: any = document.getElementsByClassName("close-model");
  console.log(collection, 8887);
  if (collection) {
    let divs = Array.prototype.forEach.call(collection, (testElement) => {
      console.log("closed", 8887);
      testElement.click();
    });
  }
};

export function handleModalClose(className) {
  const elements: any = document.getElementsByClassName(className);
  while (elements?.length > 0) {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
    elements[0].parentNode.removeChild(elements[0]);
  }
}

export function commmaSeparator(num: number) {
  if (num) {
    var result = num.toString().split(".");
      result[0] = result[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return result.join(".");
  }else return num
}

export function filterCurrentCity(cityList,currentCityId){
  console.log(cityList,"::currentcityid")
  return cityList.filter(city=>city?.id===currentCityId)
}


export const convertRentalDayIntoNumber = (day) => {
  if (day && (day.includes("Day") || day.includes("Days"))) {
    return Number(day.split(" ")[0]);
  }
  return 0;
};

export const toggleSignInDrawer = (elementId?: string) => {
  const ele = elementId ? document.getElementById(elementId) : document.getElementById('signIn-drawer')
  if (ele) ele.click()
}

export const getHttpChallenge = (xKey: string, clientId: string, platform: string, channel: string) => {
  // x-http-challenge
  const hashTextFrontend = `${clientId}:${platform}:${channel}`
  const mdTextFrontend = forge.md.sha512.create();
  mdTextFrontend.update(hashTextFrontend);
  const hashValueFrontend = mdTextFrontend.digest().toHex()
  const userChallengeFrontend = `${new Date().getTime()}::${hashValueFrontend}`
  const decodedPublicKey = forge.util.decode64(xKey);
  const publicKeyFrontend = forge.pki.publicKeyFromPem(decodedPublicKey);
  const encryptedFrontend = publicKeyFrontend.encrypt(userChallengeFrontend, 'RSA-OAEP');
  // Encode the encrypted text in Base64
  const base64EncodedFrontend = forge.util.encode64(encryptedFrontend);
  return base64EncodedFrontend;
}

export const saveTokens = (data: any) => {
  const tokenDetails = {
    expireTime: data?.data?.data?.expiry,
    token: data?.data?.data?.accessToken,
    refreshToken: data?.data?.data?.refreshToken,
    xKey: data?.headers?.["x-key"],
  }
  // populate in cookies
  Cookies.set(CookieTypes.TOKEN, JSON.stringify({
    ...tokenDetails
  }), { expires: 365 });

  store.dispatch(
    actions.setUserToken({
      ...tokenDetails
    })
  );
}

export const isAccessTokenExpired = (): boolean => {
  // Get the token from cookies
  const tokenFromCookieObj: string | undefined | any = Cookies.get('token');
  if (!tokenFromCookieObj) {
    // Handle case where token is not present
    return true;
  }
  const parsedCookieToken = JSON.parse(tokenFromCookieObj);
  const currentTime = moment();
  const expiryTime = moment(parsedCookieToken?.expireTime * 1000);
  // Check if the access token is expired or about to expire
  return expiryTime.diff(currentTime, 'seconds') < 0;
};

export const getAccessToken = async () => {
  const tokenCookieObj: any = Cookies.get('token')
  const parsedTokenCookieObj: any = JSON.parse(tokenCookieObj)

  const clientId: string = CONSTANTS?.axiosHeaders["x-client-id"];
  const platform: string = CONSTANTS?.axiosHeaders["x-platform"];
  const channel: string = CONSTANTS?.axiosHeaders["x-channel"];
  const grantType: string = CONSTANTS?.axiosHeaders["grantType"];
  const refreshToken: string | any = parsedTokenCookieObj?.refreshToken;
  const xKey: string | any = parsedTokenCookieObj?.xKey;

  let url: string = BASE_URL + "/titan-shield/authentication/v1/token?";
  url += refreshToken ? `refresh_token=${refreshToken}&` : "";
  url += grantType ? `grant_type=${grantType}` : "";

  const xHttpChallenge: string = getHttpChallenge(xKey, clientId, platform, channel) // get x-http-challenge

  let config: IConfig = {
    method: 'post',
    maxBodyLength: Infinity,
    url: url, // `https://qa-api.freedo.rentals/titan-shield/authentication/v1/token?refresh_token=${refreshToken}&grant_type=refresh`,
    headers: {
      ...defaultHeaders,
      'x-http-challenge': xHttpChallenge,
    },
  };

  try {
    const tokenResponse = await axios.request(config) // request new token
    saveTokens(tokenResponse) // save new tokens
    setAuthToken(tokenResponse?.data?.data?.accessToken) // Set the new access token in the request headers
    return tokenResponse?.data?.data?.accessToken // return new access token
  } catch (error) {
    console.error('Error getting access token', error)
  }
}
