import { BASE_URL_CUSTOMER, CHECKOUT_API_URLS } from "../../config/apiUrls";
import {
  getBranchSlotsList,
  getCouponListUrl,
  getHomeAddressList,
  getHomeDeliveryCharge,
  createBookingUrl,
  processOrderUrl,
  orderPaymentVerifyUrl,
} from "../../config/getCheckoutAPIUrls";
import { customAxios } from "../../config/axiosConfig";
import { fetchVehicleModelListDetails } from "../../config/getApiUrls";
const couponList = (params: any) =>
  customAxios.get(getCouponListUrl(params)).catch((ex: any) => ex);

const slotsList = (params: any) =>
  customAxios.get(getBranchSlotsList(params)).catch((ex: any) => ex);

const homeAddressList = (params: any) => {
  return customAxios.get(getHomeAddressList(params)).catch((ex: any) => ex);
}

const homeDeliverCharges = (params: any) =>
  customAxios.get(getHomeDeliveryCharge(params)).catch((ex: any) => ex);

const vehicleModelDetails = (params: any) =>
  customAxios
    .get(BASE_URL_CUSTOMER + fetchVehicleModelListDetails(params))
    .catch((ex) => ex);

const validateUserEmergency = () =>
  customAxios
    .get(CHECKOUT_API_URLS.validateUserEmergency)
    .catch((ex: any) => ex);

const createStoreBooking = (params: any) =>
  customAxios.post(createBookingUrl(), params).catch((ex: any) => ex);


const processOrderBooking = (params: any) =>
customAxios.post(processOrderUrl(), params).catch((ex: any) => ex);

const verifyOrderPayment = (params: any) =>
customAxios.post(orderPaymentVerifyUrl(), params).catch((ex: any) => ex);



export default {
  couponList,
  slotsList,
  homeAddressList,
  homeDeliverCharges,
  vehicleModelDetails,
  validateUserEmergency,
  createStoreBooking,
  processOrderBooking,
  verifyOrderPayment,
};
