import React, { useEffect, useRef, useState } from "react";
import { navigationAmico, startDateRent, durationRent, userVerified } from "../../assets";
import "./journeyModal.css";
import { format } from "date-fns";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatePickerInput from "../../component/DatePicker/DatePickerInput";
import { LocalStorage } from "../../constant/enum";
import { getPackageMasters, saveBasicFilter } from "../../redux/actions";
import { RootState } from "../../redux/reducers";
import { getLocalStorageData, setLocalStorageData } from "../../utills/helper";
import SelectCityModal from "./SelectCityModal";
import "./journeyModal.css";

const jrnyData = {
  city: null,
  date: null,
  endDate: null,
  package: null,
};
const JourneyModal = ({ setIsJourneyCity, isJourneyCity }) => {
  const [journyData, setJournyData] = useState<any>(jrnyData);
  const today: any = new Date();
  const [startDate, setStartDate] = useState(today);
  const navigate = useNavigate();
  const { packages,isJourneyModalOpen } = useSelector((state: RootState) => state.homeReducer);
  const { selectedCity } = useSelector(
    (state: RootState) => state.localeReducer
  );

  const dispatch = useDispatch();
  const cityData = getLocalStorageData(LocalStorage.userCity);
  const datePickerRef = useRef<any>();
  useEffect(() => {
    dispatch(
      getPackageMasters({
        id: cityData?.id,
        type: "GetPackages",
      })
    );
  }, []);
  useEffect(() => {
    const data = { ...journyData };
    if (cityData) {
      data.city = cityData;
    }
    setJournyData(data);
  }, [selectedCity]);

  const handleDatePickerOnchange = (e) => {
    let selectedDate = e;
    if (selectedDate) {
      DateFormatForPicker(e);
    }
    const data = { ...journyData };
    data.date = selectedDate;
    if (journyData.package) {
      const dt = getEndDate(selectedDate, journyData.package);
      data.endDate = DateFormat(dt);
    }
    data.package = null;
    setJournyData(data);
  };

  const handleDateClick = (e: any) => {
    datePickerRef.current.setOpen(true);
  };

  const setSelectedDate = () => {
    if (journyData.date) {
      return DateFormat(journyData.date);
    } else {
      return DateFormat(new Date());
    }
  };

  const handleCityChange = (newCity: any) => {
    const data = { ...journyData };
    if (newCity) {
      data.city = newCity;
    }
    setJournyData(data);

    const elem = document.getElementById("btn-journy-trigger");
    if (elem) {
      elem.click();
    }
  };
  const getEndDate = (datee: string, pack: any) => {
    if (datee && pack) {
      const days = pack.package_days;
      return addDays(new Date(datee), days);
    } else {
      return addDays(new Date(), 1);
    }
  };
  const proceeedToRent = () => {
    dispatch(saveBasicFilter(journyData));
    setLocalStorageData("basic-filter", journyData);
    navigate("/rent", { state: { journyData } });
  };
  const handlePackageSelected = (_e: any, indx: number) => {
    const data = { ...journyData };
    data.package = packages[indx];
    if (journyData.date) {
      const dt = getEndDate(journyData.date, packages[indx]);
      data.endDate = moment(DateFormat(dt), "DD-MM-YYYY").format("YYYY-MM-DD");
    }
    setJournyData(data);
  };
  const setSelectedPackage = (data: any) => {
    if (journyData.package) {
      if (journyData.package.id === data.id) {
        return "opacity-100";
      } else {
        return "opacity-50";
      }
    } else {
      return "opacity-100";
    }
  };
  const resetPackage = () => {
    const data = { ...journyData };
    data.package = null;
    setJournyData(data);
  };
  function addDays(theDate: Date, days: number) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }
  const DateFormat = (date = new Date(), isTime = false) => {
    let dateFormat = format(new Date(date), "dd-MM-yyy");
    if (isTime) {
      dateFormat = format(new Date(date), "dd-MM-yyy hh:mm a");
    }
    return dateFormat;
  };

  const DateFormatForPicker = (date = new Date(), isTime = false) => {
    let dateFormat = format(new Date(date), "yyyy-MM-dd");
    if (isTime) {
      dateFormat = format(new Date(date), "yyyy-MM-dd hh:mm a");
    }
    return dateFormat;
  };
  const checkDate = journyData.date ? DateFormatForPicker(journyData.date) : "";
  
  let myModal: any = document.getElementById("journyModal");
  if (myModal)
    myModal.addEventListener("hidden.bs.modal", function () {
      if (isJourneyCity) setIsJourneyCity(false);
    });

  const handleClose = () => {
    setJournyData({ ...journyData, date: null });
  };
  const formattedLaunchDate = cityData?.launch_date
    ?.split("-")
    .reverse()
    .join("-");
  let launchDateForPicker = formattedLaunchDate
    ? new Date(formattedLaunchDate)
    : new Date();
  launchDateForPicker =
    launchDateForPicker <= new Date() ? new Date() : launchDateForPicker;

  return (
    <>
      <div
        className="modal fade"
        id="journyModal"
        tab-index="-1"
        aria-labelledby="journyModalLabel"
        aria-hidden="true"
        data-bs-backdrop="true"
      >
        {/* <Modal
        show={isJourneyModalOpen}
        className="modal fade"
        id="journyModal"
        tab-index="-1"
        aria-labelledby="journyModalLabel"
        aria-hidden="true"
        data-bs-backdrop="true"
        
        onHide={()=>dispatch(toggleJourneyModal(false))}
      > */}

        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="">
                Start your Booking Journey
              </h4>
              <button
                type="button"
                className="close-model"
                data-bs-dismiss="modal"
              >
                <i className="fa-solid fa-xmark" onClick={handleClose}></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="Journey-wrap">
                <div
                  className={`city-wrap ${
                    journyData?.city?.city_name ? "" : "dashed"
                  }`}
                >
                  <div
                    className={`step-process ${
                      journyData?.city?.city_name ? "" : "dotted-circle"
                    }`}
                  >
                    1
                  </div>
                  <img src={navigationAmico} alt="" className="top-img" />
                  <div className="journey-box">
                    <h6> City </h6>
                    <p className="smallest-text">
                      {journyData?.city
                        ? `Selected location`
                        : `Select city for your trip`}
                    </p>
                    <div className="city-model-wrap">
                      <div className="mid-wrap city">
                        {journyData?.city ? (
                          <>
                            {" "}
                            <img
                              src={journyData.city.city_icon_image_path}
                              alt={journyData?.city?.city_name}
                            />
                              <p>{journyData.city.city_name}</p>
                          </>
                        ) : (
                          ""
                        )}
                      
                      </div>
                    
                    </div>
                    {/* <div className="mid-wrap"> <img src={journyData.city ? journyData.city.city_icon_image_path : ""} alt="city" /> </div> */}
                  </div>
                  <button
                    type="button"
                    className="freedo-primary-button-small rounded-pill"
                    data-bs-toggle="modal"
                   
                    data-bs-target="#exampleModalToggle"
                  >
                    {journyData.city ? "Change City" : "Select City"}
                  </button>
                </div>

                <div
                  className={`date-wrap  ${journyData.date ? "" : "dashed"}`}
                >
                  <div
                    className={`step-process ${
                      journyData.date ? "" : "dotted-circle"
                    }`}
                  >
                    2
                  </div>
                  <img src={startDateRent} alt="" className="top-img" />
                  <div className="journey-box">
                    <h6>Trip Start Date </h6>
                    <p className="smallest-text">
                      {" "}
                      Select start date to start your trip{" "}
                    </p>

                    <div className="mid-wrap datepicker">
                      <DatePickerInput
                        startDate={journyData.date}
                        handleDatePickerOnchange={handleDatePickerOnchange}
                        datePickerRef={datePickerRef}
                        minDate={launchDateForPicker}
                        isClearableEnabled={true}
                      />

                      {journyData.package && journyData.date && (
                        <>
                          <div className="progressbar-wrap">
                            <div className="journey-modal">
                              <p>{setSelectedDate()}</p>
                              <div className="progressbar">
                                <span className="active"></span>
                                <span className="next"></span>
                              </div>
                              <div className="ml-0 opacity-25">
                                <p>
                                  {journyData.endDate
                                    ? moment(
                                        journyData.endDate,
                                        "YYYY-MM-DD"
                                      ).format("DD-MM-YYYY")
                                    : ""}
                                </p>{" "}
                              </div>
                            </div>
                          </div>
                          {/* <p className=""> {setSelectedDate()} </p> */}
                        </>
                      )}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="freedo-primary-button-small rounded-pill"
                    onClick={handleDateClick}
                  >
                    {journyData.date ? "Change Date" : "Select Date"}{" "}
                  </button>
                </div>

                <div
                  className={`reset-wrap ${journyData.package ? "" : "dashed"}`}
                >
                  <div
                    className={`step-process ${
                      journyData.package ? "" : "dotted-circle"
                    }`}
                  >
                    3
                  </div>
                  <img
                    src={durationRent}
                    alt=""
                    className={`top-img ${!journyData.date && "disable-img"}`}
                  />
                  <div
                    className={`journey-box ${
                      !journyData.date && "disable-package"
                    }`}
                  >
                    <h6>Duration</h6>
                    <p
                      className={`smallest-text ${
                        !journyData.date && "package-txt"
                      }`}
                    >
                      {" "}
                      Select the total time span of the trip{" "}
                    </p>
                    <div className={`mid-wrap time-wrap`}>
                      {packages.map((item: any, indx: number) => {
                        return (
                          <div
                            key={indx}
                            className={`time-box ${
                              item.tag && "favourite"
                            } ${setSelectedPackage(item)}
                            }`}
                            onClick={(e) => handlePackageSelected(e, indx)}
                          >
                            {journyData?.package?.package_name ==
                            item?.package_name ? (
                              <div>
                                <h5>{item?.package_name}</h5>
                                <img
                                  className="verified-use"
                                  src={userVerified}
                                  alt=""
                                  style={{
                                    top: "0",
                                    left: "-3px",
                                    height: "14px",
                                  }}
                                />
                              </div>
                            ) : (
                              <h5>{item?.package_name}</h5>
                            )}
                            {/* <p>Starting from: 299/-</p> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="freedo-primary-button-small rounded-pill"
                    onClick={resetPackage}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                data-bs-dismiss="modal"
                disabled={
                  journyData.city == null ||
                  journyData.date == null ||
                  journyData.package == null
                }
                className="freedo-primary-button-lg rounded-pill"
                onClick={proceeedToRent}
              >
                Go Freedo
              </button>
            </div>
          </div>
        </div>
        {/* </Modal> */}
      </div>
      <SelectCityModal handleChange={handleCityChange} />
    </>
  );
};

export default JourneyModal;
