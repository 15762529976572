import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { STATUS } from "../../../../constant/enum";
import {
  getExtendDetails,
  payExtraChargesAction,
} from "../../../../redux/actions/rentActions/bookingAction";
import moment from "moment";
import { activeRibbon, ribbon } from "../../../../assets";
const { Ongoing, Upcoming, Cancelled, Pending, Ended, Completed } = STATUS;
const ExtraCharges = ({
  id,
  bookingStat,
  extraCharges,
  extendRides,
  callOrderApi,
  extraChargesTab,
}) => {
  const [payingCharges, setPayingCharges] = useState<any>([]);
  const [extraChargesPayment, setExtraChargesPayment] = useState<number>(0);
  const [customerPayCharges, setCustomerPayCharges] = useState<any>([]);
  const [totalPendingAmount, setTotalPendingAmount] = useState<any>(0);
  const [extendedAmount, setExtendedAmount] = useState(0);
  const [extendChargesTab, setExtendChargesTab] = useState(false);
  const [extraChargeLoader, setExtraChargeLoader] = useState<boolean>(false);
  const dispatch = useDispatch();

  let total_paid = 0;
  const payCharges = () => {
    let paymentExample = extraChargesPayment;
    let arr = [...payingCharges];
    let custPay = [...customerPayCharges];
    let tp = totalPendingAmount;
    let extendAmount = 0;
    for (var i = 0; i < extraCharges?.length; i++) {
      if (extraCharges[i].status == 1) {
        custPay.push(extraCharges[i]);
        arr.push(extraCharges[i].id);
        paymentExample = paymentExample + Number(extraCharges[i].amount);
        tp = tp + Number(extraCharges[i].amount);
      } else if (
        extraCharges[i].status == 2 &&
        extraCharges[i].bAc?.charge_id == 23
      ) {
        // amnt = amnt + extraCharges[i].amount;
      }
    }
    for (let i = 0; i < extendRides?.length; i++) {
      if (extendRides[i].status == 2)
        extendAmount += Number(extendRides[i]?.amount) || 0;
    }
    setExtendedAmount(extendAmount);

    if (bookingStat != Cancelled) setTotalPendingAmount(tp);
    setCustomerPayCharges(custPay);
    setPayingCharges(arr);
    setExtraChargesPayment(paymentExample);
  };
  useEffect(() => {
    if (extraChargesTab) dispatch(getExtendDetails({ id }));
  }, [extraChargesTab]);
  useEffect(() => {
    if (extraCharges && totalPendingAmount == 0) payCharges();
  }, [extraCharges]);

  const ExtendChargeModal = () => {
    return (
      <div
        className="modal extend-ride-wrapper fade backdrop show"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content extRide">
            <div className="modal-header custom-modal">
              <h5>Extend Ride</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setExtendChargesTab(false)}
              ></button>
            </div>
            <div className="modal-body row">
              {extendRides?.length > 0 &&
                extendRides.map((item, index) => {
                  return (
                    <>
                      <div className="col-md-2">
                        <div className="ride-address ExtendRide">
                          <div className="pickup-ride">
                            <p>
                              <b>Extension End Date</b>
                              {moment(item?.extension_end_date).format(
                                "DD-MMM-YYYY"
                              )}
                            </p>
                          </div>
                          <div className="drop-ride">
                            <p>
                              <b>Extension Start Date</b>
                              {moment(item?.extension_start_date).format(
                                "DD-MMM-YYYY"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-10">
                        <div className="card mb-5 mt-3 border-0">
                          <div className="card-body">
                            <div className="extentionCard">
                              <img
                                src={index == 0 ? activeRibbon : ribbon}
                                alt="image"
                                className="Extribbon"
                              />
                              <div className="extentionText">
                                Extension {extendRides?.length - index}
                              </div>
                            </div>

                            <div className="row">
                              <div className="table-responsive col-md-5">
                                <table className="table table-hover table-lg table-borderless">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <b>Package (Days)</b>
                                      </td>
                                      <td>
                                        <p
                                          className="text-start"
                                          style={{ textAlign: "left" }}
                                        >
                                          {" "}
                                          {item?.package_name}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Extension Mode </b>
                                      </td>
                                      <td>
                                        <p className="text-start">
                                          {" "}
                                          {item?.extention_mode}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Payment Mode </b>
                                      </td>
                                      <td>
                                        <p className="text-start">
                                          {" "}
                                          {item?.payment_mode}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Extension Date </b>
                                      </td>
                                      <td>
                                        <p className="text-start">
                                          {" "}
                                          {moment(item?.extention_date).format(
                                            "DD-MMM-YYYY"
                                          )}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Start Odometer Reading</b>
                                      </td>
                                      <td>
                                        <p className="text-start">
                                          {" "}
                                          {item?.odometer_start_reading}
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-md-2 d-flex justify-content-center">
                                <div className="vr"></div>
                              </div>
                              <div className="table-responsive col-md-5">
                                <table className="table table-hover table-lg table-borderless">
                                  <tbody>
                                    {item?.odometer_end_reading == null ? (
                                      ""
                                    ) : (
                                      <>
                                        <tr>
                                          <td>
                                            <b>End Odometer Reading</b>
                                          </td>
                                          <td>
                                            <p className="text-start">
                                              {" "}
                                              {item?.odometer_end_reading}
                                            </p>
                                          </td>
                                        </tr>
                                      </>
                                    )}

                                    <tr>
                                      <td>
                                        <b>Applied Coupon </b>
                                      </td>
                                      <td>
                                        <p className="text-start">
                                          {" "}
                                          {item?.coupon ? item.coupon : "N/A"}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Amount Paid </b>
                                      </td>
                                      <td>
                                        <p className="text-start">
                                          {" "}
                                          Rs. {item?.amount_paid}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Amount Remaining </b>
                                      </td>
                                      <td>
                                        <p className="text-start">
                                          {" "}
                                          Rs. {item?.amount_left}
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>

            {/* <div className="d-flex mt-3">
                  <b>Extended Ride Calculated Amount</b>
                  <p className="ps-5">
                    <b>{"₹" + Number(extendedAmount)}</b>
                  </p>
                </div> */}
          </div>
        </div>
      </div>
    );
  };
  const paymentOngoingAdditional = ({
    additional_charge,
    additional_charges_ids,
  }) => {
    setExtraChargeLoader(true);
    let payload = {
      booking_id: id,
      additional_charges_ids,
    };
    dispatch(
      payExtraChargesAction(payload, handleSuccess, setExtraChargeLoader)
    );
  };

  const handleSuccess = (data) => {
    callOrderApi(data.Result?.amount, "5", data?.Result?.helpData, stopLoader);
  };
  const stopLoader = () => setExtraChargeLoader(false);

  return (
    <div
      id="collapseFive"
      className="accordion-collapse collapse"
      aria-labelledby="headingFive"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <div className="Vehicle-Price-Details">
          <div className="table-responsive">
            <table className="table table-borderless table-sm paid-extra">
              <thead>
                <tr>
                  <th>
                    <p>Paid Extra Charges</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {extendedAmount > 0 && (
                  <>
                    <tr className="tr-bottom-border mb-2">
                      <td>
                        Extended Ride
                        <i
                          onClick={() => setExtendChargesTab(true)}
                          className="fa-solid fa-circle-info ms-1"
                        ></i>
                      </td>
                      <td> {"₹" + Math.round(extendedAmount)}</td>
                    </tr>
                    {extendChargesTab && <ExtendChargeModal />}
                  </>
                )}
                {extraCharges?.length > 0 &&
                  extraCharges.map((item) => {
                    if (
                      item.status == 2 &&
                      item.bAc?.charge_id != 23 &&
                      item?.bAc?.charge_name?.category_id != 4 &&
                      item?.charge_type != 3
                    ) {
                      total_paid += Number(item.amount);
                      return (
                        <tr style={{ width: "100%" }}>
                          <td>{item.bAc?.charge_name?.charge_name}</td>
                          <td>{"₹" + Math.round(item.amount)}</td>
                        </tr>
                      );
                    }
                  })}
                <tr>
                  <td>Total Paid Amount</td>
                  <td>{"₹" + Math.round(total_paid)}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>
                    <p>Pending Extra Charges</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {customerPayCharges &&
                  customerPayCharges.length > 0 &&
                  bookingStat != Cancelled &&
                  customerPayCharges.map((item) => {
                    return (
                      <tr>
                        <td>{item.bAc?.charge_name?.charge_name}</td>
                        <td>{"₹" + Math.round(item.amount)}</td>
                      </tr>
                    );
                  })}
                {(bookingStat == Pending ||
                  bookingStat == Upcoming ||
                  bookingStat == Ongoing ||
                  bookingStat == Ended) &&
                  totalPendingAmount > 0 ? (
                  <>
                    <tr>
                      <td className="fw-bold">Total Sum</td>
                      <td className="fw-bold">
                        {"₹" + Math.round(totalPendingAmount)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <div className="text-center">
                          <button
                            className="freedo-primary-button mt-2 rounded-pill text-dark"
                            onClick={() =>
                              paymentOngoingAdditional({
                                additional_charge: totalPendingAmount,
                                additional_charges_ids: [...payingCharges],
                              })
                            }
                          >
                            {extraChargeLoader ? (
                              <>
                                <div
                                  className="spinner-border spinner-border-sm text-light"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                <p className="fw-bold">
                                  Pay Now{" "}
                                  {" ₹" + Math.round(totalPendingAmount)}
                                </p>
                              </>
                            )}
                          </button>
                        </div>
                      </td>
                    </tr>
                  </>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtraCharges;
