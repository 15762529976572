import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { freedoLogo, userAvatar } from "../assets";
import { CookieTypes, LocalStorage } from "../constant/enum";
import { Routes } from "../constant/routeContants";
import en from "../locales/en.json";
import { actions } from "../redux";
import { RootState } from "../redux/reducers";
import SignInPopUp from "../screen/homeScreen/SIgnInPopUp";
import { getLocalStorageData, handleModalClose } from "../utills/helper";
import { getCityListMasters } from "../redux/actions";
import SuccessModal from "./SuccessModal";
import Cookies from "js-cookie";
import { NODE_ENV } from "../config/envConfig";


declare global {
  interface Window {
    YellowMessengerPlugin: any;
  }
}

const HeaderBar = () => {
  const { header, AuthScreens } = en;
  const pathname = window.location.pathname;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated, userData, signInLoader } = useSelector(
    (state: RootState) => state.authReducer
  );
  const { showSuccessModal, showErrorModal, modalMessage } = useSelector(
    (state: RootState) => state.localeReducer
  );
  const [showDrawer, setShowDrawer] = useState(false);
  const [showMobDrawer, setShowMobDrawer] = useState(false);

  window.onpopstate = function () {
    handleModalClose("modal-backdrop");
  };
  const { userProfileDetail } = useSelector(
    (state: RootState) => state.profileReducer
  );


  ///function to initiate the yellow ai chatbot with user data
  const yellowAiChatbot = () => {
    const cityData = getLocalStorageData(LocalStorage.userCity);

    ///if user is looged in then this payload will be send in yellow.ai
    let loggedInpayload = {
      "email": userData?.email_id,
      "name": userData?.first_name,
      "phoneNumber": userData?.mobile_number,
      "cityId": JSON.stringify(cityData?.id),
      "city_name": cityData?.city_name
    }

    ///this payload is for default city to yellow ai
    let loggedOutpayload = {
      "cityId": JSON.stringify(cityData?.id),
      "city_name": cityData?.city_name
    }

    if (isAuthenticated) {
      window.YellowMessengerPlugin?.init({
        "bot": NODE_ENV === "production" ? "x1704945252589" : "x1704945255144",
        "host": "https://cloud.yellow.ai",
        "payload": loggedInpayload,
        "ymAuthenticationToken": JSON.stringify(userData?.id)
      });
    } else {
      window.YellowMessengerPlugin?.init({
        "bot": NODE_ENV === "production" ? "x1704945252589" : "x1704945255144",
        "host": "https://cloud.yellow.ai",
        "payload": loggedOutpayload,
      });
    }
  }

  useEffect(() => {
    dispatch(getCityListMasters({ type: "GetCityList", payload: {} }));
  }, []);

  useEffect(() => {
    // if (isAuthenticated) {
    yellowAiChatbot()
    // }
  }, [userData, window.YellowMessengerPlugin])

  const onClickLogout = (e) => {
    e.preventDefault();
    Cookies.set(CookieTypes.TOKEN, null!);
    navigate("/");
    dispatch(actions.logout());
  };

  const toggleDrawer = () => {
    setShowDrawer((pre) => !pre);
    dispatch(actions?.clearPaginationData());
  };

  const toggleMobDrawer = () => setShowMobDrawer((pre) => !pre);

  const handleFreedoLogo = () => {
    if (pathname === "/") window.scrollTo(0, 0);
  };

  const toggleNavMenu = () => {
    if (window.innerWidth < 992) {
      const ele = document.getElementById("collapseNavMobile");
      if (ele) ele.click();
    } else {
      setShowDrawer(false);
    }
  };
  useEffect(() => {
    function handleClickOutside(e) {
      let ele: any = document.getElementById("navbarSupportedContent");
      if (ele?.contains(e.target)) {
        // Clicked in box
      } else if (ele?.className?.includes("show")) {
        toggleNavMenu();
        // Clicked outside the box
      }
    }
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const onErrorProfileImage = (e: any) => {
    e.target.src = userAvatar;
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <NavLink className="navbar-brand" to="/">
            <img
              src={freedoLogo}
              alt="freedo-brand"
              onClick={handleFreedoLogo}
            />
          </NavLink>
          <button
            id="collapseNavMobile"
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          /* onClick={toggleMobDrawer} */
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`navbar-collapse collapse ${showMobDrawer ? "show" : ""
              }`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav m-auto mb-2 mb-lg-0">
              {header.HeaderRoutes.map(({ path, label }, ind) => (
                <li key={ind} className="nav-item fr-nav-item">
                  <Link
                    data-bs-target="#navbarSupportedContent"
                    className={`nav-link ${path === pathname ? "active" : "fr-nav-link"
                      }`}
                    to={path}
                    onClick={() => {
                      toggleNavMenu();
                    }}
                  >
                    {label}
                  </Link>
                </li>
              ))}
            </ul>

            {!(isAuthenticated && Object.keys(userData)?.length) ? (
              <div className="d-flex login-wrap mb-4 mb-lg-0">
                <button
                  className="freedo-primary-button rounded-pill"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasSignIn"
                  onClick={() => {
                    toggleNavMenu();
                  }}
                >
                  {isAuthenticated && signInLoader ? (
                    <>
                      <span className="spinner-border text-light">
                        <span className="visually-hidden">Loading...</span>
                      </span>
                    </>
                  ) : (
                    <>{AuthScreens.SignIn}</>
                  )}
                </button>
                <button
                  className="d-none"
                  id="signIn-drawer"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasSignIn"
                ></button>
              </div>
            ) : (
              <div className="btn-group user-btn">
                <Dropdown
                  show={showDrawer}
                  onToggle={toggleDrawer}
                  id="userSettingsDropdown"
                >
                  <Dropdown.Toggle
                    className="navbar-text btn btn-default border-0"
                    id="userSettingsDropdown"
                  >
                    <img
                      src={userProfileDetail.profile_image_url}
                      className="user-img"
                      onError={onErrorProfileImage}
                    />
                    <i className="fas fa-ellipsis-h ms-2 user-icon"></i>{" "}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <li className="dropdown-item">
                      <span>Hey</span>
                      <br />
                      <b>
                        {`${userData?.first_name ?? ""} ${userData?.last_name ?? ""
                          }`}{" "}
                        !
                      </b>
                    </li>
                    <li className="ps-3 mt-2 mb-1">
                      <Link
                        onClick={toggleNavMenu}
                        to={Routes.PROFILE}
                        title="User Profile"
                        state={{ values: "my-profile" }}
                      >
                        <i className="fas fa-user-circle pe-2"></i> My Profile
                      </Link>
                    </li>
                    <li className="ps-3 mb-1">
                      <Link
                        onClick={toggleNavMenu}
                        to={Routes.PROFILE}
                        state={{ values: "kyc" }}
                        title="User KYC"
                      >
                        <i className="fas fa-certificate pe-2"></i> KYC
                      </Link>
                    </li>
                    <li className="ps-3 mb-1">
                      <Link
                        onClick={toggleNavMenu}
                        to={Routes.PROFILE}
                        state={{ values: "my-bookings" }}
                        title="All Bookings"
                      >
                        <i className="fas fa-calendar-alt pe-2"></i> My Bookings
                      </Link>
                    </li>
                    <li className="ps-3 mb-1">
                      <Link
                        onClick={toggleNavMenu}
                        to={Routes.PROFILE}
                        state={{ values: "my-address" }}
                        title="Address Book"
                      >
                        <i className="fas fa-address-book pe-2"></i> My
                        Addresses
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li className="ps-3 mb-1">
                      <a
                        // href="javascript:void(0)"
                        href="#"
                        title="Logout"
                        onClick={(e: any) => {
                          onClickLogout(e);
                          toggleNavMenu();
                        }}
                      >
                        <i className="fas fa-sign-out-alt pe-2"></i> Sign Out
                      </a>
                    </li>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      </nav>
      <SignInPopUp />
      {(showSuccessModal || showErrorModal) && (
        <SuccessModal
          isSuccess={showSuccessModal}
          modalMessage={modalMessage}
        />
      )}
      {/* {showDrawer && <SignInPopUp handleclose={setShowDrawer} /> } */}
    </>
  );
};

export default HeaderBar;
