import { call, put, select, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import { handleMessage, toggleSignInDrawer } from "../../utills/helper";
import { toastr } from "react-redux-toastr";
import { authServices } from "../services";
import { setAuthToken } from "../../config/axiosConfig";
import * as selectors from "../selectors";
import { CookieTypes } from "../../constant/enum";
import Cookies from "js-cookie";

function* userLogin(action: any): any {
  const data = yield call(authServices.userLogin, action.payload);
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.AUTH_ACTIONS.USER_LOGIN_SUCCEEDED,
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.AUTH_ACTIONS.USER_LOGIN_FAILED,
    });
  }
}

function* logout(): any {
  const data = yield call(authServices.userLogout);

  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.AUTH_ACTIONS.LOGOUT_SUCCESS,
    });
  } else {
    toastr.error("", handleMessage(data));
    window.location.reload();
    // yield put({
    //   type: actionTypes.AUTH_ACTIONS.LOGOUT_FAILED,
    // });
  }
}

function* otpVerification(action: any): any {
  const data = yield call(authServices.verifyOTP, action.payload.param);
  if (data?.status === 200) {
    const tokenDetails = {
      expireTime: data?.data?.ExpireTime,
      token: data?.data?.Token,
      refreshToken: data?.data?.RefreshToken,
      xKey: data?.headers?.["x-key"],
    }
    // populate in cookies
    Cookies.set(CookieTypes.TOKEN, JSON.stringify({
      ...tokenDetails
    }), { expires: 365 })

    yield put({
      type: actionTypes.AUTH_ACTIONS.SET_USER_TOKEN,
      payload: {
        ...tokenDetails
      }
    })

    const bookingData = yield select(selectors.bookingData);
    toastr.success("", handleMessage(data));
    toggleSignInDrawer("closeX_signInPopup")

    yield put({
      type: actionTypes.AUTH_ACTIONS.OTP_VERIFICATION_SUCCEEDED,
      payload: {
        ...tokenDetails,
        userData: data.data?.Result,
        access_uuid_id: data.data?.access_uuid_id,
      },
    });

    yield put({ type: actionTypes.PROFILE_ACTIONS.GET_USER_PROFILE });
    if (bookingData?.payload?.model_id) {
      yield put({
        type: actionTypes.CHECKOUT_ACTIONS.GET_VEHICLE_DATA,
        payload: {
          payload: bookingData.payload,
          journyData: bookingData.journyData,
          navigate: action.payload.navigate,
          handleRentingFleetLoader: null,
        },
      });

      yield put({
        type: actionTypes.CHECKOUT_ACTIONS.SET_BOOKING_DATA,
        payload: { payload: {}, journyData: {} },
      });
    }
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.AUTH_ACTIONS.OTP_VERIFICATION_FAILED,
    });
  }
}

function* sendOtpForSignUp(action: any): any {
  const data = yield call(authServices.sendOtpForSignUp, action.payload);
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.AUTH_ACTIONS.SENT_OTP_FOR_SIGNUP_SUCCEEDED,
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.AUTH_ACTIONS.SENT_OTP_FOR_SIGNUP_FAILED,
    });
  }
}

function* userSignUp(action: any): any {
  const data = yield call(authServices.userSignUp, action.payload);
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    const tokenDetails = {
      expireTime: data?.data?.ExpireTime,
      token: data?.data?.Token,
      refreshToken: data?.data?.RefreshToken,
      xKey: data?.headers?.["x-key"],
    }
    // populate in cookies
    Cookies.set(CookieTypes.TOKEN, JSON.stringify({
      ...tokenDetails
    }), { expires: 365 })

    yield put({
      type: actionTypes.AUTH_ACTIONS.SET_USER_TOKEN,
      payload: {
        ...tokenDetails
      }
    })
    yield put({
      type: actionTypes.AUTH_ACTIONS.USER_SIGNUP_SUCCEEDED,
      payload: {
        tokenData: data?.data,
        xKey: data?.headers["x-key"],
        userData: data?.data?.Result,
      },
    });
    toggleSignInDrawer()
    setAuthToken(data?.data?.Token);
    yield put({ type: actionTypes.PROFILE_ACTIONS.GET_USER_PROFILE });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.AUTH_ACTIONS.USER_SIGNUP_FAILED,
    });
  }
}

function* resendOtp(action: any): any {
  try {
    const data = yield call(authServices.userLogin, action.payload);
    if (data?.status === 200) {
      toastr.success("", handleMessage(data));
      yield put({
        type: actionTypes.AUTH_ACTIONS.RESEND_OTP_SUCCEEDED,
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.AUTH_ACTIONS.RESEND_OTP_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.AUTH_ACTIONS.RESEND_OTP_FAILED,
    });
  }
}

function* getDelAccOptions(action: any): any {
  try {
    const data = yield call(authServices.getOptions, action.payload);
    if (data?.status === 200) {
      yield put({
        type: actionTypes.DELETE_ACCOUNT_ACTION.GET_DEL_ACC_OPTIONS_SUCCESS,
        payload: data.data.Result,
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.DELETE_ACCOUNT_ACTION.GET_DEL_ACC_OPTIONS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.DELETE_ACCOUNT_ACTION.GET_DEL_ACC_OPTIONS_FAILED,
    });
  }
}

function* sendOtpForDelAccount(action: any): any {
  try {
    const data = yield call(authServices.getOtpForDelAcc, action.payload);
    if (data?.status === 200) {
      yield put({
        type: actionTypes.DELETE_ACCOUNT_ACTION.SEND_OTP_FOR_DEL_ACC_SUCCEEDED,
      });
      toastr.success("", handleMessage(data));
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.DELETE_ACCOUNT_ACTION.SEND_OTP_FOR_DEL_ACC_FAILED,
      });
    }
  } catch (ex) {
    yield put({
      type: actionTypes.DELETE_ACCOUNT_ACTION.SEND_OTP_FOR_DEL_ACC_FAILED,
    });
  }
}

function* changeAccountStatus(action: any): any {
  try {
    const data = yield call(authServices.changeStatus, action.payload.data);
    if (data?.status === 200) {
      yield put({
        type: actionTypes.DELETE_ACCOUNT_ACTION.CHANGE_ACC_STATUS_SUCCEEDED,
      });
      toastr.success("", handleMessage(data));
      action.payload.navigateToHome();
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.DELETE_ACCOUNT_ACTION.CHANGE_ACC_STATUS_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.DELETE_ACCOUNT_ACTION.CHANGE_ACC_STATUS_FAILED,
    });
  }
}

function* authSaga() {
  yield takeLatest(actionTypes.AUTH_ACTIONS.USER_LOGIN_REQUESTED, userLogin);
  yield takeLatest(actionTypes.AUTH_ACTIONS.LOGOUT, logout);

  yield takeLatest(
    actionTypes.AUTH_ACTIONS.OTP_VERIFICATION_REQUESTED,
    otpVerification
  );
  yield takeLatest(
    actionTypes.AUTH_ACTIONS.SENT_OTP_FOR_SIGNUP_REQUESTED,
    sendOtpForSignUp
  );
  yield takeLatest(actionTypes.AUTH_ACTIONS.USER_SIGNUP_REQUESTED, userSignUp);

  yield takeLatest(actionTypes.AUTH_ACTIONS.RESEND_OTP_REQUESTED, resendOtp);

  yield takeLatest(
    actionTypes.DELETE_ACCOUNT_ACTION.GET_DEL_ACC_OPTIONS_REQUESTED,
    getDelAccOptions
  );

  yield takeLatest(
    actionTypes.DELETE_ACCOUNT_ACTION.SEND_OTP_FOR_DEL_ACC_REQUESTED,
    sendOtpForDelAccount
  );

  yield takeLatest(
    actionTypes.DELETE_ACCOUNT_ACTION.CHANGE_ACC_STATUS_REQUESTED,
    changeAccountStatus
  );
}

export default authSaga;
