export enum LocalStorage {
  token = "token",
  userData = "userData",
  userCity = "user-city",
  zohoLeads = "zoho-leads",
  bookingDetails = "booking-details",
}
export enum AppLink {
  playstoreLink = "https://play.google.com/store/apps/details?id=com.heerofreedo",
  appStoreLink = "https://apps.apple.com/in/app/freedo-rentals/id1603635910",
}
export enum BOOKING_DATA {
  ADDITIONAL_HELMET = 100,
}
export enum STATUS {
  Pending = "Pending",
  Upcoming = "Upcoming",
  Ongoing = "Ongoing",
  Ended = "Ended",
  Cancelled = "Cancelled",
  Completed = "Completed",
}

export enum WAREHOUSE_STATUS {
  Rejected = "rejected",
  Accepted = "accepted",
  Inprocess = "in-process",
}

export enum GOOGLE_MAP {
  key = "AIzaSyC9fP2BopC2GADep1fbIOCrLvGSjywhcUs",
}

export enum windowSize {
  extraLarge = 1200,
  large = 992,
  medium = 768,
}

export enum thankyouPage {
  MITC_Verified = "mitc-verified",
}

export enum toastMessages {
  mandatoryFields = "All fields are mandatory",
}

export enum modalMessages {
  modifyBookingSuccess = "Booking Modification Successful",
  modifyBookingFailed = "Booking Modification Failed",
}

export enum gender {
  male = 1,
  female = 2,
  others = 3,
}

export enum CookieTypes {
  TOKEN = "token",
}
