import axios from "axios";
import { BASE_URL_CUSTOMER } from "./apiUrls";
import store from "../redux/store";
import { showUnderMaintenance, userLogout } from "../redux/actions";
import CONSTANTS from '../locales/en.json';
import { getAccessToken, isAccessTokenExpired } from "../utills/helper";
import { toastr } from "react-redux-toastr";

interface IDefaultHeaders {
  "x-platform": string;
  "x-channel": string;
  "x-bn": string | any;
  "x-client-id": string;
  "user-agent": string;
  "requestfrom": string;
  "platform": string;
}

export const defaultHeaders: IDefaultHeaders | any = {
  "x-platform": CONSTANTS?.axiosHeaders["x-platform"],
  "x-channel": CONSTANTS?.axiosHeaders["x-channel"],
  "x-client-id": CONSTANTS?.axiosHeaders["x-client-id"],
  "x-bn": process.env.REACT_APP_BUILD_NUMBER,
  "user-agent": navigator.userAgent,
  "requestfrom": CONSTANTS?.axiosHeaders?.requestfrom, // old header
  "platform": CONSTANTS?.axiosHeaders?.platform, // old header
}

export const customAxios = axios.create({
  baseURL: BASE_URL_CUSTOMER,
  headers: {
    ...defaultHeaders
  }
});
export const Axios = axios.create({});
Axios.defaults.headers.common = { ...defaultHeaders }

let isRefreshing: boolean = false;
let refreshPromise: any = null;
let updatesAccessToken: any = null

// This intercepts requests made using a customAxios instance. It checks if the access token is expired 
// or about to expire. If so, it initiates a token refresh process if one isn't already ongoing. It then waits 
// for the refresh to complete before updating the request headers with the new access token.
customAxios.interceptors.request.use(
  async (config: any) => {
    // Check if the access token is expired or about to expire
    if (isAccessTokenExpired()) {
      // If not already refreshing, initiate token refresh
      if (!isRefreshing) {
        isRefreshing = true;
        // Create a promise to handle token refresh
        refreshPromise = new Promise((resolve, reject) => {
          // Get a new access token
          getAccessToken()
            .then((newAccessToken) => {
              // Update the access token
              updatesAccessToken = newAccessToken;
              // Set the new access token in the request headers
              // setAuthToken(newAccessToken);
              config.headers['Authorization'] = `Bearer ${newAccessToken}`;
              // Resolve the promise with the updated config
              resolve(config);
            })
            .catch((error) => {
              // Log and reject if there's an error refreshing the token
              console.error('Error refreshing token:', error);
              toastr.error("Oops", CONSTANTS?.errorMessages?.RefreshTokenError);
              reject(error);
            })
            .finally(() => {
              // Reset the refreshing state and promise after completion
              isRefreshing = false;
              refreshPromise = null;
            });
        });
      }
      // Wait for token refresh completion
      await refreshPromise;
      // Set the updated access token in the request headers
      setAuthToken(updatesAccessToken);
      config.headers['Authorization'] = `Bearer ${updatesAccessToken}`;
    }
    return config;
  },
  (error) => {
    // Handle request error
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

customAxios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const { status } = error?.response;
    if (status === 401) {
      store.dispatch(userLogout());
    }
    if (status === 502) {
      store.dispatch(showUnderMaintenance(true));
      return Promise.reject({ Message: 'Under Maintenance' });
    }
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const { status } = error.response;
    if (status === 502) {
      store.dispatch(showUnderMaintenance(true));
      return Promise.reject({ Message: 'Under Maintenance' });
    }
    return Promise.reject(error);
  }
);

Axios.interceptors.request.use(
  async (config: any) => {
    return config
  },
  (error) => {
    console.error(error)
  }
);

customAxios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const { status } = error.response;
    if (status === 401) {
      store.dispatch(userLogout());
    }
    if (status === 502) {
      store.dispatch(showUnderMaintenance(true));
      return Promise.reject({ Message: 'Under Maintenance' });
    }
    return Promise.reject(error);
  }
);

export const setAuthToken = (token: string) =>
(customAxios.defaults.headers.common = {
  ...defaultHeaders,
  Authorization: `Bearer ${token}`,
});
