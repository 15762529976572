import { FC, useEffect, useRef, useState } from "react";
import BookingStatusModal from "./BookingStatusModal";
import EndRideModal from "./EndRideModal";
import ExtendRideModal from "./ExtendRideModal";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GoogleMap } from "@react-google-maps/api";
import PriceDetailsTab from "./Tabs/PriceDetailsTab";
import ExtendRideTab from "./Tabs/ExtendRideTab";
import MyDocumentTab from "./Tabs/MyDocumentsTab";
import BookingSkelaton from "../../../component/SkelatonLoader/BookingSkelaton";
import {
  getBookingDetailsById,
  getExtendDetails,
  resetBookingDetails,
  reSetExtendRideState,
} from "../../../redux/actions/rentActions/bookingAction";
import {
  commmaSeparator,
  getDecryptedText,
  getEncryptedText,
} from "../../../utills/helper";
import {
  modalMessages,
  STATUS,
  WAREHOUSE_STATUS,
} from "../../../constant/enum";
import ExtraCharges from "./Tabs/ExtraCharges";
import CustomModalVehicleDetail from "../../../component/CustomModalVehicleDetail";
import {
  processOrder,
  setModalMessage,
  showApiFailModal,
  showApiSuccessModal,
  verifyOrderPayment,
} from "../../../redux/actions";
import { RAZORPAY_KEY } from "../../../config/apiUrls";
import { toastr } from "react-redux-toastr";
import MitcTab from "./Tabs/MitcTab";
import { FirebaseEvents } from "../../../utills/firebaseEvents";
import { Routes } from "../../../constant/routeContants";
import CancleBookingModal from "./CancleBookingModal";
import CancellationTab from "./Tabs/CancellationTab";
import ModifyBookingModal from "./ModifyBookingModal";
import { HelpModal } from "../../../component/HelpModal";
var CryptoJS = require("crypto-js");

const { Ongoing, Upcoming, Cancelled, Pending, Ended, Completed } = STATUS;
const { Rejected, Inprocess } = WAREHOUSE_STATUS;

const BookingDetails: FC = () => {
  const scrollRef: any = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sklFlag, setSkl] = useState(true);
  const { encrypted } = useParams();
  const {
    bookingId,
    packageId,
    param_extraChargesOfExcessKms,
    param_extendHelmetCharges,
    param_helmetCatgoryId,
    param_odometerReading,
    isExtendedFromBookingList,
  } = JSON.parse(getDecryptedText(encrypted));
  const bookingDetailsById = useSelector(
    (state: any) => state?.bookingReducer?.bookingDetails
  );
  const { userProfileDetail } = useSelector(
    (state: any) => state.profileReducer
  );
  const extendDetails = useSelector(
    (state: any) => state?.bookingReducer?.extendDetails
  );
  const { modifyBookingState } = useSelector(
    (state: any) => state?.bookingReducer
  );
  const { modifyStep } = modifyBookingState;

  const [modelFlag, setModalFlag] = useState({
    endRide: false,
    extendRide: false,
    bookingStatus: false,
    refundScreen: false,
    extendTab: packageId ? true : false,
    myDocumentTab: false,
    priceDetailsTab: false,
    extraChargesTab: false,
    mitcTab: false,
    packageChargeTab: false,
    addressTab: false,
    cancleRideModal: false,
    cancellationTab: false,
    modifyRideModal: false,
  });
  const [extendState, setExtendState] = useState({
    extendPackageId: packageId || null,
    extraChargesOfExcessKms: param_extraChargesOfExcessKms || 0,
    extendHelmetCharges: param_extendHelmetCharges || 0,
    helmetCatgoryId: param_helmetCatgoryId || null,
    odometerReading: param_odometerReading || 0,
  });
  const [openDetailModal, setOpenDetailModal] = useState(false);

  const {
    start_date,
    end_date,
    buffer_end_date,
    amount,
    bike_name,
    booking_id,
    coupon_code,
    drop_off_location,
    id,
    pickup_location,
    rental_days,
    status,
    vehicleImagePath,
    bike_image,
    pick_up_lattitude,
    pick_up_longitude,
    branch_id,
    brand_id,
    vehicle_id,
    registration_number,
    start_time,
    end_time,
    drop_off_start_time,
    drop_off_end_time,
    odometer_start_reading,
    odometer_end_reading,
    excess_per_km_charge,
    excess_per_hour_charge,
    model_id,
    warehouse_status,
    additional_charges,
    mode_of_pick_up_id,
    mode_of_drop_off_id,
    is_cod,
    cancellationData,
    moduleBranches,
    city_id,
    is_modified,
    dropOffSlotHidden,
  } = bookingDetailsById;

  const {
    endRide,
    extendRide,
    bookingStatus,
    extendTab,
    priceDetailsTab,
    myDocumentTab,
    extraChargesTab,
    mitcTab,
    packageChargeTab,
    addressTab,
    cancleRideModal,
    cancellationTab,
    refundScreen,
    modifyRideModal,
  } = modelFlag;

  const {
    extraChargesOfExcessKms,
    extendPackageId,
    extendHelmetCharges,
    helmetCatgoryId,
    odometerReading,
  } = extendState;
  let Duration = moment
    .duration(moment(end_date).diff(moment(start_date)))
    .asDays();
  Duration = Duration ? Math.floor(Duration) : 0;

  useEffect(() => {
    dispatch(getBookingDetailsById({ booking_id: bookingId }));
    dispatch(getExtendDetails({ id: bookingId }));
    if (!packageId) dispatch(reSetExtendRideState());
    return () => {
      dispatch(resetBookingDetails());
      dispatch(reSetExtendRideState());
    };
  }, []);

  useEffect(() => {
    if (document.getElementById("collapseSix") && packageId) {
      handleExtendTab();
    }
  }, [document.getElementById("collapseSix")]);

  useEffect(() => {
    setTimeout(() => {
      setSkl(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (extendTab && scrollRef)
      scrollRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
  }, [extendTab, scrollRef]);

  const encryptRazorPay = (plainText: any) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(plainText),
      "@dmin"
    ).toString();

    return ciphertext;
  };
  const callOrderApi = async (amount, paidFor, helpData, stopLoader) => {
    let tempAmount = parseFloat(amount).toFixed(2);

    let responseData = {
      data: encryptRazorPay({
        amount: Number(tempAmount),
        bookingId: id,
        userId: userProfileDetail?.id,
        paidFor: paidFor,
        helpData: helpData ? helpData : {},
        branchId: branch_id,
      }),
    };
    dispatch(
      processOrder({
        payload: responseData,
        callBack: handleProcessOrderCallback,
        handleLoader: stopLoader,
      })
    );
  };
  const handleProcessOrderCallback = (data: any, stopLoader) => {
    displayRazorpay(
      data.Result.id,
      data.Result.amount,
      stopLoader,
      data?.Result?.rzpkey
    );
  };

  const loadScript = (src: any) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async (
    orderid: any,
    amount: any,
    stopLoader,
    rzpKey: string
  ) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    console.log("displayRazorpay opend");
    if (!res) {
      stopLoader();
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    } else stopLoader();
    const options = {
      key: rzpKey ? rzpKey : RAZORPAY_KEY,
      amount: parseInt(amount),
      currency: "INR",
      timeout: 300,
      name: "Freedo Rentals",
      order_id: orderid,
      description: "Credits towards Freedo Rentals",
      prefill: {
        name: userProfileDetail?.first_name,
        email: userProfileDetail?.email_id,
        contact: userProfileDetail?.mobile_number,
      },
      theme: {
        color: "#2BC7C9",
      },
      config: {
        display: {
          blocks: {
            banks: {
              name: "All payment methods",
              instruments: [
                {
                  method: "upi",
                },
                {
                  method: "card",
                },
                {
                  method: "wallet",
                },
                {
                  method: "netbanking",
                },
                {
                  method: "emi",
                },
                {
                  method: "cardless_emi",
                },
              ],
            },
          },
          sequence: ["block.banks"],
          preferences: {
            show_default_blocks: false,
          },
        },
      },

      handler: async function (response: any) {
        console.log("RESPONSE>>>", response);
        const reqPayload = {
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          //razorpay_signature: response.razorpay_signature,
        };
        dispatch(
          verifyOrderPayment({
            payload: reqPayload,
            callBack: paymentVerificationCallback,
          })
        );
      },
    };
    console.log("options", options);
    const paymentObject = new (window as any).Razorpay(options);
    paymentObject.open();
  };
  const paymentVerificationCallback = (response: any) => {
    if (response?.data) {
      if (extendTab) {
        console.log("ExtendRidePaymentSuccess", {
          amount: response.data?.Result?.amount,
          date: moment(response.data?.Result?.transactionDate).format(
            "DD-MM-YYYY"
          ),
        });
        FirebaseEvents("ExtendRidePaymentSuccess", {
          amount: response.data?.Result?.amount,
          date: moment(response.data?.Result?.transactionDate).format(
            "DD-MM-YYYY"
          ),
        });
      }
      if (response.data?.Result?.paymentStatus == "pending") {
        window.open(
          `${process.env.PUBLIC_URL}/payment-pending-web/${response.data.Result.orderId}`
        );
      } else if (status === Upcoming) {
        // for modify booking
        dispatch(getBookingDetailsById({ booking_id: bookingId }));
        dispatch(showApiSuccessModal(true));
        dispatch(setModalMessage(modalMessages.modifyBookingSuccess));
      } else {
        toastr.success("Success", "Payment successful");
        setModalFlag((prev) => ({
          ...prev,
          extendTab: false,
          extendRide: false,
          extraChargesTab: false,
        }));
        setExtendState((prev) => ({
          ...prev,
          extendPackageId: null,
          extraChargesOfExcessKms: 0,
        }));
        if (packageId) {
          let object = {
            bookingId,
          };
          const encryptedObject = getEncryptedText(JSON.stringify(object));
          navigate(`/booking-details/${encryptedObject}`);
        } else {
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      }
    } else {
      if (extendTab) {
        console.log("ExtendRidePaymentFailure", {});
        FirebaseEvents("ExtendRidePaymentFailure", {});
      } else if (status === Upcoming) {
        //modify booking
        dispatch(showApiFailModal(true));
        dispatch(setModalMessage(modalMessages.modifyBookingFailed));
      }
      toastr.error("Oops", response?.data?.result?.Message);
    }
  };

  const handleExtendTab = () => {
    let ele = document.getElementById("collapseSix");
    if (ele) ele.classList.add("show");
    collapseAllAccordians();
  };
  const collapseAllAccordians = () => {
    let ids = [
      "collapseOne",
      "collapseTwo",
      "collapseThree",
      "collapseFour",
      "collapseFive",
      "collapseSeven",
    ];
    ids.forEach((id) => {
      let ele = document.getElementById(id);
      if (ele && ele.className.includes("show")) ele.classList.remove("show");
    });
    setModalFlag((prev) => ({
      ...prev,
      myDocumentTab: false,
      priceDetailsTab: false,
      extraChargesTab: false,
      mitcTab: false,
      packageChargeTab: false,
      addressTab: false,
    }));
  };

  const handleCancleBooking = () => {
    setModalFlag((pre) => ({
      ...pre,
      cancleRideModal: true,
    }));
  };

  const handleModifyBooking = () => {
    setModalFlag((pre) => ({
      ...pre,
      modifyRideModal: true,
    }));
  };

  return Object.keys(bookingDetailsById).length ? (
    <section className="Booking-details-wrapper">
      {cancleRideModal && status === Upcoming && !is_cod && (
        <CancleBookingModal setModalFlag={setModalFlag} />
      )}
      {modifyRideModal && (
        <ModifyBookingModal
          callOrderApi={callOrderApi}
          setModalFlag={setModalFlag}
        />
      )}
      {bookingStatus && (
        <BookingStatusModal
          status={status}
          warehouse_status={warehouse_status}
          onHide={setModalFlag}
          refundScreen={refundScreen}
          bookingId={bookingId}
        />
      )}
      {/* {endRide && (
        <EndRideModal
          onHide={setModalFlag}
          bookingDetailsById={bookingDetailsById}
          callOrderApi={callOrderApi}
        />
      )} */}
      {extendRide && (
        <ExtendRideModal
          packageDetail={bookingDetailsById?.plan}
          cityId={bookingDetailsById?.city_id}
          onHide={setModalFlag}
          bookingDetailsById={bookingDetailsById}
          branch_id={branch_id}
          setExtendState={setExtendState}
          handleExtendTab={handleExtendTab}
        />
      )}
      <div className="container-fluid">
        <div className="renting-wrap">
          <h2>Booking Details</h2>
          <div className="breadcrumb-wrap mb-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={Routes.PROFILE} state={{ values: "my-bookings" }}>
                    My Bookings
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Booking Details
                </li>
              </ol>
            </nav>
          </div>
          <div className="need-help-btn-container">
            <HelpModal />
          </div>
          <div className="row mt-3">
            <div className="col-lg-7">
              <div className="booking-status-wrapper">
                <div
                  className={`booking-status-top ${
                    status === Upcoming && !is_cod ? "" : "align-items-center"
                  }`}
                >
                  <div className="booking-bike-wrap">
                    <div className="booking-bike-model">
                      <img
                        className="bike-brand cp"
                        data-bs-toggle="modal"
                        data-bs-target="#bookvehicle"
                        onClick={() => setOpenDetailModal(true)}
                        src={vehicleImagePath + bike_image}
                        alt=""
                      />
                    </div>
                    <div className="bike-model-details">
                      <h6>{bike_name}</h6>
                      <CustomModalVehicleDetail
                        openDetailModal={openDetailModal}
                        setOpenDetailModal={setOpenDetailModal}
                        handleBookVehicle={null}
                        modelId={model_id}
                        showBookVehicle={false}
                      />
                      {/* <div className="bike-name">{vechileModal.brand_name}</div> */}
                      <div>
                        Rental Plan:
                        <span className="plan-days"> {rental_days} Plan</span>
                      </div>
                    </div>
                  </div>
                  <div className="booking-status-btn">
                    <div className="mb-2">
                      <Link
                        to="#"
                        className="m-2 freedo-outline-btn-small rounded-pill"
                        onClick={() => {
                          setModalFlag((prev) => ({
                            ...prev,
                            bookingStatus: true,
                          }));
                        }}
                      >
                        Booking Status
                      </Link>
                      {status === Upcoming && !is_modified && !is_cod && (
                        <a
                          onClick={handleModifyBooking}
                          className="cp modify-booking-btn rounded-pill"
                        >
                          Modify Ride
                        </a>
                      )}
                      {status === Upcoming && !is_cod && is_modified && (
                        <>
                          <a
                            onClick={handleCancleBooking}
                            className="cp cancel-booking-btn rounded-pill"
                          >
                            Cancel Ride
                          </a>
                        </>
                      )}
                    </div>
                    {status === Upcoming && !is_cod && is_modified && (
                      <div className="text-end">
                        <Link to={Routes.TERMS_CONDITIONS}>
                          Freedo Refund & Cancellation Policy
                        </Link>
                      </div>
                    )}
                    {status === Upcoming && !is_cod && !is_modified && (
                      <div
                        onClick={handleCancleBooking}
                        className={`text-end cancle-ride-btn-modify ${
                          status === Upcoming && !is_cod ? "" : "d-none"
                        }`}
                      >
                        <a
                          href=""
                          onClick={(e) => e.preventDefault()}
                          className="mx-1"
                        >
                          Cancel Ride
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="booking-status-bottom">
                <div className="booking-id-first">
                  <div className="booking-id-wrap">
                    <h6>
                      Booking ID :<span></span>
                    </h6>
                    <h4 className="mb-2">{booking_id}</h4>
                    <span
                      className={`Upcoming-ride-btn ${
                        status === Upcoming && warehouse_status === Inprocess
                          ? "approval"
                          : status?.toLowerCase()
                      }`}
                    >
                      {status === Upcoming && warehouse_status === Inprocess
                        ? "Approval In Progress"
                        : `${status} Ride`}
                    </span>
                  </div>
                </div>
                <div className="booking-id-mid">
                  <div className="row mb-2">
                    <div className="col-md-3 col-sm-6">
                      <label>Pick up date</label>
                      <h4>
                        {moment(start_date).format("DD-MMM-YYYY") || "-NA-"}
                      </h4>
                    </div>
                    <div className="col-md-5 col-sm-6">
                      <label>
                        {mode_of_pick_up_id == 2
                          ? "Pick Up Location"
                          : "Home Delivery Location"}
                      </label>
                      <h4> {pickup_location} </h4>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <label>Hand Over Time</label>
                      <h4>
                        {bookingDetailsById?.handover_time
                          ? moment(bookingDetailsById?.handover_time).format(
                              "lll"
                            )
                          : "-NA-"}
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Planned Drop-off Date</label>
                      <h4>
                        {moment(end_date).format("DD-MMM-YYYY") || "-NA-"}
                      </h4>
                    </div>
                    <div className="col-md-5 col-sm-6">
                      <label>
                        {mode_of_drop_off_id == 1
                          ? "Home Pick Up Location"
                          : "Drop Off Location"}
                      </label>
                      <h4>{drop_off_location || "-NA-"}</h4>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <label>Actual Drop-off Date</label>
                      <h4>
                        {bookingDetailsById?.dropof_time
                          ? moment(bookingDetailsById?.dropof_time).format(
                              "DD-MMM-YYYY"
                            )
                          : "-NA-"}
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Applied Coupons</label>
                      <h4>{coupon_code ? coupon_code : "-NA-"}</h4>
                    </div>
                    <div className="col-md-5 col-sm-6">
                      <label>Vehicle Registered</label>
                      <h4>{registration_number || "-NA-"}</h4>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <label> Start Odometer Reading</label>
                      <h4>
                        {odometer_start_reading
                          ? `${odometer_start_reading} KM`
                          : "-NA-"}
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Duration</label>
                      <h4>
                        {Duration
                          ? Duration > 1
                            ? `${Duration} Days`
                            : `${Duration} Day`
                          : "-NA-"}
                      </h4>
                    </div>
                    <div className="col-md-5 col-sm-6">
                      <label>Excess Km Charges</label>
                      <h4>
                        {excess_per_km_charge
                          ? `₹ ${Math.round(excess_per_km_charge)}/km`
                          : "-NA-"}
                      </h4>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <label>Excess Hourly Charges</label>
                      <h4>
                        {excess_per_hour_charge
                          ? `₹ ${Math.round(excess_per_hour_charge)}/hr`
                          : "-NA-"}
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Pick Up Slot</label>
                      <h4>
                        {start_time ? start_time + "-" + end_time : "-NA-"}
                      </h4>
                    </div>
                    {!dropOffSlotHidden && (
                      <div className="col-md-5 col-sm-6">
                        <label>Drop Off Slot</label>
                        <h4>
                          {drop_off_start_time
                            ? drop_off_start_time + "-" + drop_off_end_time
                            : "-NA-"}
                        </h4>
                      </div>
                    )}
                    {(status == Ended || status == Completed) && (
                      <div className="col-md-4 col-sm-6">
                        <label>End Odometer Reading</label>
                        <h4>
                          {odometer_end_reading
                            ? `${odometer_end_reading} KM`
                            : "-NA-"}
                        </h4>
                      </div>
                    )}
                  </div>
                </div>
                {status?.toLowerCase() === Ongoing?.toLowerCase() ? (
                  <div className="booking-id-last">
                    <div className="text-end">
                      {!extendTab && (
                        <>
                          {/* {!additional_charges && (
                            <a
                              className={`border rounded-pill danger-btn text-danger border-danger ${
                                !extendTab ? "cp" : "cursor-default"
                              }`}
                              onClick={() => {
                                if (!extendTab)
                                  setModalFlag((prev) => ({
                                    ...prev,
                                    endRide: true,
                                  }));
                              }}
                            >
                              End Ride
                            </a>
                          )} */}

                          <a
                            className={`freedo-primary-button rounded-pill text-dark ${
                              extendTab && "cursor-default"
                            }`}
                            onClick={() => {
                              if (!extendTab)
                                setModalFlag((prev) => ({
                                  ...prev,
                                  extendRide: true,
                                }));
                            }}
                          >
                            Extend Ride
                          </a>
                        </>
                      )}
                      {/* <!-- Extend Ride model --> */}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-lg-5">
              <div className="booking-detailsfaq">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={`accordion-button ${
                          !packageChargeTab && "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        Rental Package Details
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="Vehicle-Price-Details">
                          <div className="table-responsive">
                            <table className="table table-borderless table-sm">
                              <tbody>
                                <tr>
                                  <td>Package </td>
                                  <td>
                                    {rental_days} (₹
                                    {commmaSeparator(Math.round(amount))})
                                  </td>
                                </tr>
                                {bookingDetailsById?.additional_helmet && (
                                  <tr>
                                    <td className="active">Extras</td>
                                    <td className="active">
                                      Additional Helmet
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {pickup_location != null ? (
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className={`accordion-button ${
                            !addressTab && "collapsed"
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="true"
                          data-bs-parent="#accordionExample"
                          aria-controls="collapseTwo"
                        >
                          Delivery Address Details
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="delivery-address-details-wrap">
                            {pick_up_lattitude && pick_up_longitude ? (
                              <div className="googleMapDiv">
                                <GoogleMap
                                  mapContainerStyle={{
                                    width: "35vh",
                                    height: "20vh",
                                  }}
                                  center={{
                                    lat: pick_up_lattitude,
                                    lng: pick_up_longitude,
                                  }}
                                  zoom={12}
                                  options={{ fullscreenControl: false }}
                                  // clickableIcons={false}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="details">
                              {mode_of_pick_up_id == 1
                                ? "Home Delivery"
                                : "Pick Up"}
                              <p>{pickup_location}</p>
                              {pick_up_lattitude && pick_up_longitude ? (
                                <a
                                  href={`https://maps.google.com/?q=${pick_up_lattitude},${pick_up_longitude}`}
                                  target="_blank"
                                >
                                  View Map
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className={`accordion-button ${
                          !priceDetailsTab && "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        onClick={() => {
                          setModalFlag((state) => ({
                            ...state,
                            priceDetailsTab: true,
                          }));
                        }}
                      >
                        Vehicle Price Details
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="Vehicle-Price-Details Price-details-accordian">
                          <PriceDetailsTab
                            priceDetailsTab={priceDetailsTab}
                            id={id}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {status === Cancelled && !is_cod && (
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingEight">
                        <button
                          className={`accordion-button ${
                            !cancellationTab && "collapsed"
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                          onClick={() => {
                            setModalFlag((state) => ({
                              ...state,
                              cancellationTab: true,
                            }));
                          }}
                        >
                          Cancelled Booking Details
                        </button>
                      </h2>
                      <CancellationTab
                        cancellationData={{ amount, ...cancellationData }}
                        setModalFlag={setModalFlag}
                      />
                    </div>
                  )}
                  {status === Ongoing && (
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className={`accordion-button ${
                            !myDocumentTab && "collapsed"
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                          onClick={() => {
                            setModalFlag((state) => ({
                              ...state,
                              myDocumentTab: true,
                            }));
                          }}
                        >
                          My Documents
                        </button>
                      </h2>
                      <MyDocumentTab myDocumentTab={myDocumentTab} />
                    </div>
                  )}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className={`accordion-button ${
                          !extraChargesTab && "collapsed"
                        }`}
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                        type="button"
                        onClick={() => {
                          setModalFlag((state) => ({
                            ...state,
                            extraChargesTab: true,
                          }));
                        }}
                      >
                        Extra Charges
                      </button>
                    </h2>
                    <ExtraCharges
                      extraCharges={extendDetails?.extraCharge}
                      bookingStat={status}
                      extendRides={extendDetails?.extention}
                      id={id}
                      callOrderApi={callOrderApi}
                      extraChargesTab={extraChargesTab}
                    />
                  </div>
                  <div
                    className={`accordion-item ${
                      (!extendPackageId || !extendTab || status !== Ongoing) &&
                      "d-none"
                    }`}
                  >
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className={`accordion-button ${
                          !extendTab && "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                        onClick={() => {
                          setModalFlag((state) => ({
                            ...state,
                            extendTab: true,
                          }));
                        }}
                      >
                        Extended Ride
                      </button>
                    </h2>
                    <ExtendRideTab
                      setModalFlag={setModalFlag}
                      id={id}
                      packageId={extendPackageId}
                      branchId={branch_id}
                      cityId={bookingDetailsById?.city_id}
                      brand_id={brand_id}
                      vehicle_id={vehicle_id}
                      ExcessKmsCharges={extraChargesOfExcessKms}
                      callOrderApi={callOrderApi}
                      extendHelmetCharges={extendHelmetCharges}
                      helmetCatgoryId={helmetCatgoryId}
                      model_id={model_id}
                      odometerReading={odometerReading}
                      moduleBranches={moduleBranches}
                      booking_id={booking_id}
                      isExtendedFromBookingList={isExtendedFromBookingList}
                    />
                  </div>
                  {!(status === Upcoming && warehouse_status == Inprocess) && (
                    <div className="accordion-item" ref={scrollRef}>
                      <h2 className="accordion-header" id="headingSeven">
                        <button
                          className={`accordion-button ${
                            !mitcTab && "collapsed"
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                          onClick={() => {
                            setModalFlag((state) => ({
                              ...state,
                              mitcTab: true,
                            }));
                          }}
                        >
                          MITC Forms
                        </button>
                      </h2>
                      <MitcTab mitcTab={mitcTab} bookingId={bookingId} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <BookingSkelaton />
  );
};

export default BookingDetails;
